import styled from 'styled-components';
// import Theme from '../Settings/theme';

// const {
//     colors: {
//         quaternaryColor
//     }
// } = Theme;

export const Container = styled.div`
    text-align: center;
`;